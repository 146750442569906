var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type == 0)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.liveList,"header-cell-style":{
      background: '#EFF6FF',
      color: '#606266',
      fontSize: '14px',
      fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei',
      fontWeight: 'bold',
      color: '#333333',
    }}},_vm._l((_vm.tableHeader),function(item,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":true,"prop":item.val,"label":item.name,"width":item.width}})}),1):_vm._e(),(_vm.type == 1)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.videoList,"header-cell-style":{
      background: '#EFF6FF',
      color: '#606266',
      fontSize: '14px',
      fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei',
      fontWeight: 'bold',
      color: '#333333',
    }}},_vm._l((_vm.tableHeader),function(item,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":true,"prop":item.val,"label":item.name,"width":item.width}})}),1):_vm._e(),(_vm.type == 2)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.examList,"header-cell-style":{
      background: '#EFF6FF',
      color: '#606266',
      fontSize: '14px',
      fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei',
      fontWeight: 'bold',
      color: '#333333',
    }}},_vm._l((_vm.tableHeader),function(item,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":true,"prop":item.val,"label":item.name,"width":item.width}})}),1):_vm._e(),(_vm.type == 4)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataList,"header-cell-style":{
      background: '#EFF6FF',
      color: '#606266',
      fontSize: '14px',
      fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei',
      fontWeight: 'bold',
      color: '#333333',
    }}},_vm._l((_vm.tableHeader),function(item,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":true,"prop":item.val,"label":item.name,"width":item.width}})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }